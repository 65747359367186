import React, { Component } from 'react'
import { LoginWrapper } from './style.js';
import { Form, Input, Button, message } from 'antd';
import {  } from '../../static/js/cookie';
import { phpName, homeUrl } from '../../static/js/file';
import { isLogined } from '../../static/js/auth';
import { postAxios } from '../../static/js/config';
import bg from '../../static/images/login.png';
let isLogin = isLogined()

export default class login extends Component {
	
	onFinish(values){
		postAxios(phpName+'/index/gettoken', {}).then(res=>{
			if(res.code===1){
				let postData = {
					username: values.username,
					password: values.password,
					__token__: res.data.__token__,
					is_newadmin: '1'
				}
				postAxios(phpName+'/index/login', postData).then(resA=>{
					if(resA.code===1){
                        sessionStorage.setItem('donghao_user', JSON.stringify(resA.data))
						if(resA.ruleids){
                            sessionStorage.setItem('donghao_ruleids', resA.ruleids.toString())
						}
						window.location.href = homeUrl
					}else{
						message.error(resA.msg)
					}
				})
			}else{
				message.error(res.msg)
			}
		})
	};

	// 渲染
	componentDidMount(){
        if(isLogin){
            window.location.href = homeUrl
        }
	}

    render() {
        return (
            <LoginWrapper>
                <img className='bgimg' src={bg} alt=""></img>
                <div className='word-box'>
                    <p className='english'>HELLO</p>
                    <p className='english'>WELCOME</p>
                    <p className='chinese'>欢迎进入登录页面</p>
                    <p className='chinese'>登录有任何问题请及时联系</p>
                </div>
                <div className="LoginBox">
					<p className="title flex-column-center">登录</p>
					<Form name="login" layout='vertical' onFinish={this.onFinish.bind(this)}>	
                        <Form.Item
                            name="username"
							rules={[{ required: true, message: '账号不能为空！' }]}
                        >
                            <Input placeholder="请输入账号" autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                            name="password"
							rules={[{ required: true, message: '密码不能为空！' }]}
                        >
                            <Input.Password type="password" placeholder="请输入密码" />
                        </Form.Item>
						<Form.Item className='btn-item'>
							<Button type="primary" htmlType="submit">
								登录
							</Button>
						</Form.Item>
					</Form>
				</div>
            </LoginWrapper>
        )
    }
}

