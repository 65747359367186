import { NavLink } from 'react-router-dom' 
import ViewRouter from '../../router/index' // 封装好的路由
import { withRouter } from 'react-router';
import { NavWrapper } from './style.js';
import { MenuUnfoldOutlined, MenuFoldOutlined  } from '@ant-design/icons';
import { Layout, Menu, message, Breadcrumb, Tabs, Select, Divider } from 'antd';
import React, { Component, Fragment } from 'react';
import { loginUrl, homeUrl, phpName } from '../../static/js/file';
import { postAxios } from '../../static/js/config';
import { getCategory } from '../../static/js/auth';
import menuList from '../../static/js/menu.js';
const { Header, Content, Footer, Sider } = Layout;

export default withRouter(class nav extends Component {
	constructor(props){
		super(props);
		this.state = {
            username: '',  // 用户昵称
            pathname: '',  // 选中路径
            openKey: '',  // 展开导航
            titleOne: '',  // 第一标题
            titleTwo: '',  // 第二标题
            titleThree: '',  // 第三标题--编辑/添加
            titleLink: '',  // 链接跳转
            menuList: [],
            collapsed: false,
            tabsKey: '/index',
            tabsList: [],
            typeList: [],  // 物业分类
            typename: 0,
        }
    };

    // 获取物业分类列表
    getTypeList(){
        let _this = this
        let typename = 0
        let typeid = sessionStorage.getItem("typeID")
		getCategory('project').then(function(res) {
            if(!typeid){
                sessionStorage.setItem("typeID", res[0].id)
                typename = parseInt(res[0].id)
            }else{
                typename = parseInt(typeid)
            }
			_this.setState({
				typeList: res,
                typename
			})
		})
    };
    // 选中物业分类
    onChangeType(value){
        sessionStorage.setItem("typeID", value)
        window.location.reload()
    };

    // 初始化导航
    initMenu(){
        let _this = this
        let specificMenu = []
        let rulesList = ['*']
        if(sessionStorage.getItem("donghao_ruleids")){
            rulesList = sessionStorage.getItem("donghao_ruleids").split(',')
        }
        
        if(rulesList[0]==='*'){
            specificMenu = menuList
        }else{
            specificMenu.push(menuList[0])
            let removeIndex = []
            menuList.forEach(item=> {
                if (rulesList.indexOf(item.id) > -1) {
                    if(item.children){
                        item.children.forEach(itemA=> {
                            if (rulesList.indexOf(itemA.id) > -1) {
                            }else{
                                removeIndex.push(itemA.id)
                            }
                            item.children = item.children.filter(itemB => !removeIndex.includes(itemB.id))
                        })
                    }
                    specificMenu.push(item)
                }
            })
        }
        const urlParams = new URL(window.location.href);
        let path = urlParams?.pathname
        if(this.props.location.search){
            path = path + this.props.location.search
        }

        _this.setState({
            username: JSON.parse(sessionStorage.getItem("donghao_user")).username,
            menuList: specificMenu
        })
        let patharr = path.split('/')
        patharr.splice(1,1)
        path = patharr.join('/')
        _this.initBread(path, specificMenu)
        // 初始化标签页
        let tabs = JSON.parse(sessionStorage.getItem("donghao_tabs"))
        _this.initTabs(tabs, path, specificMenu)
    };

    // 点击切换导航
    menuClick =e=>{
        let _this = this
        const { menuList, tabsList } = _this.state
        message.destroy();
        let path = e.key
        _this.initBread(path, menuList)
        // 初始化标签页
        _this.initTabs(tabsList, path, menuList)
        _this.props.history.push(path)
    };

    // 页头面包屑
    initBread(path, menu){
        // second是二级导航的key， first是一级导航的key，path是路径
        let _this = this
        // 处理路由
        let arr = path.split('?')
        let arr_s = arr[0].split('/')
        let newArr = arr_s.splice(1);
        let firstKey = '', titleOne = '', titleTwo ='', titleThree = '', titleLink= '', secondKey = ''
        if(newArr[0]==='insert' || newArr[0]==='detail' || newArr[0]==='lists' || newArr[0]==='approval' || newArr[0]==='details'){
            firstKey = '/' + newArr[1]
            let newArr_s = newArr.splice(1);
            secondKey = '/' + newArr_s.join('/')
            // 详情
            // 判断是否是编辑/添加/审批
            if(newArr[0]==='lists'){
                titleThree = '列表'
            }else if(newArr[0]==='approval'){
                titleThree = '审批'
            }else if(newArr[0]==='details'){
                titleThree = '详情'
            }else if(newArr[0]==='insert'){
                // 没有携带id--添加
                titleThree = '添加'
            }else{
                // 携带id--编辑
                titleThree = '编辑'
            }
        }else{
            // 列表
            firstKey = '/' + newArr[0]
            secondKey = '/' + newArr.join('/')
        }
        for(let i=0;i<menu.length;i++){
            if(firstKey===menu[i].key){
                titleOne = menu[i].label
                if(menu[i].children){
                    // 存在二级菜单
                    for(let j=0;j<menu[i].children.length;j++){
                        if(secondKey===menu[i].children[j].key){
                            titleTwo = menu[i].children[j].label
                            titleLink = menu[i].children[j].url
                        }
                    }
                }else{
                    // 不存在二级菜单
                    titleTwo = ''
                    titleLink = menu[i].url
                }
            }
        }
        _this.setState({
            pathname: secondKey,
            titleOne,
            titleTwo,
            titleThree,
            titleLink,
            openKey: firstKey
        })
        // setTimeout(()=>{
        //     _this.props.history.push(path)
        // },100)
    };
    // 初始化标签页
    initTabs(list, path, menu){
        let _this = this
        let arr = path.split('?')
        let arr_s = arr[0].split('/')
        let newArr = arr_s.splice(1);
        let label = '', tabsList = list, labelName = '', secondKey, newArr_s = []
        if(newArr[0]==='insert' || newArr[0]==='detail' || newArr[0]==='lists' || newArr[0]==='approval' || newArr[0]==='details'){
            // 详情
            newArr_s = newArr.splice(1);
            secondKey = '/' + newArr_s[0]
        }else{
            // 列表
            secondKey = '/' + newArr[0]
        }
        let newpath = '/' + newArr_s.join('/')
        for(let i=0;i<menu.length;i++){
            if(path===menu[i].key){
                // 当前页面url === 一级菜单路径
                label = menu[i].label
            }else if(secondKey===menu[i].key){
                if(menu[i].children){
                    // 有二级菜单
                    for(let j=0;j<menu[i].children.length;j++){
                        // 当前页面url === 二级菜单路径
                        if(path===menu[i].children[j].key){
                            label = menu[i].children[j].label
                        }
                        // 当前页面详情切割url === 二级菜单路径
                        if(newpath===menu[i].children[j].key){
                            labelName = menu[i].children[j].label
                        }
                    }
                }else{
                    // 没有二级菜单
                    if(newpath===menu[i].key){
                        labelName = menu[i].label
                    }
                }
                if(newArr[0]==='detail'){
                    // 携带id--编辑
                    label = '编辑' + labelName
                }
                if(newArr[0]==='insert'){
                    // 没有携带id--添加
                    label = '添加' + labelName
                }
                if(newArr[0]==='lists'){
                    label = labelName + '列表'
                }
                if(newArr[0]==='approval'){
                    label = '审批' + labelName
                }
                if(newArr[0]==='details'){
                    label = labelName + '详情'
                }
            }
        }
        // 第一次没有菜单
        if(!list){
            tabsList = [
                {
                    label,
                    key: path
                }
            ]
        }else{
            let flag = false
            for(let i=0;i<list.length;i++){
                if(path===list[i].key){
                    flag = true
                }
            }
            if(!flag){
                tabsList = [...list, {
                    label,
                    key: path
                }];
            }
        }
        _this.setState({
            tabsList,
            tabsKey: path
        })
		sessionStorage.setItem('donghao_tabs', JSON.stringify(tabsList))
		sessionStorage.setItem('donghao_tabskey', path)
    }
    // 展开/收起
    onOpenChange =e=>{
        let _this = this
        message.destroy();
        let key = ''
        if(e.length>1){
            key = e[1]
        }else{
            key = ''
        }
        _this.setState({
            openKey: key
        })
    };

    // 展开/收起左侧导航
    setCollapsed(){
        let _this = this
        const { collapsed } = _this.state
        _this.setState({
            collapsed: !collapsed
        })
    }

    // 退出登录
    loginOut(){
        let postData = {
            is_newadmin: '1'
        }
        postAxios(phpName+'/index/logout', postData).then(res=>{
            if(res.code===1){
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = loginUrl
            }
        })
    };
    // 切换标签页
    changeTabs = (key) => {
        let _this = this
        _this.setState({
            tabsKey: key
        })
        message.destroy();
        sessionStorage.setItem('donghao_tabskey', key)
        _this.props.history.push(key)
    };
    editTabs = (targetKey, action) => {
        if (action === 'add') {
        } else {
          this.removeTabs(targetKey);
        }
    };
    // 删除标签页
    removeTabs = (targetKey) => {
        let _this = this
        const { tabsList } = _this.state
        const urlParams = new URL(window.location.href);
        let path = urlParams?.pathname
        if(this.props.location.search){
            path = path + this.props.location.search
        }
        let arr = path.split('/')
        let newArr = arr.splice(2)
        let thePath = '/' + newArr.join('/')
        let newTabs = [], pathKey = ''
        // 只有一个标签
        if(tabsList.length===1){
            newTabs = tabsList
        }else{
            newTabs = tabsList.filter((item) => item.key !== targetKey);
        }
        if(targetKey===thePath){
            pathKey = newTabs[newTabs.length - 1].key
        }else{
            pathKey = thePath
        }
        _this.setState({
            tabsList: newTabs,
            tabsKey: pathKey
        })
        message.destroy();
		sessionStorage.setItem('donghao_tabs', JSON.stringify(newTabs))
		sessionStorage.setItem('donghao_tabskey', pathKey)
        if(tabsList.length!==1){
            setTimeout(()=>{
                _this.props.history.push(pathKey)
            },500)
        }
    };
	componentDidMount () {
        if(this.props.location.pathname==='/'){
            window.location.href = homeUrl
        }else{
            this.initMenu()  // 初始化导航
        }
        this.getTypeList()
        
	}
    //路由改变触发
    componentWillUpdate(nextProps) {
        let _this = this
        const { menuList, tabsList } =  _this.state
        // 判断跳转路由不等于当前路由
        if (nextProps.location.pathname !== this.props.location.pathname) {
            let path = nextProps.location.pathname
            if(nextProps.location.search){
                path = path + nextProps.location.search
            }
            _this.initBread(path, menuList)
            // 初始化标签页
            _this.initTabs(tabsList, path, menuList)
            //将url传出去
            _this.props.history.push(path)
            window.parent.postMessage(JSON.stringify({pagename:window.location.href}), "*");
        }
    }

    render() {
        const { menuList, pathname, openKey, username, titleOne, titleTwo, titleThree, titleLink, collapsed, tabsKey, tabsList, typeList, typename } = this.state;
        return (
            <NavWrapper>
                <Layout style={{ minHeight: '100vh' }}>
                    {/* 导航 */}
                    <Sider trigger={null} collapsible collapsed={collapsed}
                        style={{
                            overflow: 'auto',
                            height: '100vh',
                            position: 'fixed',
                            left: 0,
                            top: 0,
                            bottom: 0,
                        }}>
                        <div className="logo">
                            <NavLink to='/index'>{collapsed?'东豪':'物业管理系统'}</NavLink>
                        </div>
                        <Menu theme="dark" selectedKeys={pathname} mode="inline" onClick={this.menuClick.bind(this)} openKeys={[openKey]} onOpenChange={this.onOpenChange} items={menuList} />
                    </Sider>
                    <Layout className="site-layout" style={{ marginLeft: collapsed?80:200 }}>
                        {/* 头部 */}
                        <Header className="site-layout-background page-header flex-between-center">
                            <div className='flex-between-center'>
                                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                    className: 'trigger',
                                    onClick: () => this.setCollapsed(),
                                })}
                                <div className="bread-box">
                                {
                                    titleTwo===''?
                                    <Breadcrumb>
                                        <Breadcrumb.Item>{titleOne}</Breadcrumb.Item>
                                        <Breadcrumb.Item>
                                            <NavLink to={titleLink}>列表</NavLink>
                                        </Breadcrumb.Item>
                                    {
                                        titleThree === ''?null : 
                                        <Breadcrumb.Item>{titleThree}</Breadcrumb.Item>
                                    }
                                    </Breadcrumb>
                                    :
                                    <Breadcrumb>
                                        <Breadcrumb.Item>{titleOne}</Breadcrumb.Item>
                                        <Breadcrumb.Item>
                                            <NavLink to={titleLink}>{titleTwo}</NavLink>
                                        </Breadcrumb.Item>
                                        <Fragment>
                                        {
                                            titleThree === ''?null : <Breadcrumb.Item>{titleThree}</Breadcrumb.Item>
                                        }
                                        </Fragment>
                                    </Breadcrumb>
                                }   
                                </div>
                            </div>
                            <div className='header-right'>
                                <Select dropdownMatchSelectWidth={false} value={typename} onChange={this.onChangeType.bind(this)} options={typeList}
                                    fieldNames={{ label: 'name', value: 'id' }} />
                                <Divider type="vertical" />
                                <span className='right_span'>{username}</span>
                                <Divider type="vertical" />
                                <span className='right_span' onClick={this.loginOut}>退出</span>
                            </div>
                        </Header>
                        {/* 内容 */}
                        <Content>
                            <div className="page-content">
                                {
                                   tabsList.length===0?null:
                                   <Tabs
                                       hideAdd
                                       onChange={(activeKey)=>this.changeTabs(activeKey)}
                                       activeKey={tabsKey}
                                       type="editable-card"
                                       onEdit={(activeKey)=>this.editTabs(activeKey)}
                                       items={tabsList}
                                   />
                                }
                                <ViewRouter />
                            </div>
                        </Content>
                        {/* 底部 */}
                        <Footer style={{ textAlign: 'center' }}>东豪物业管理系统</Footer>
                    </Layout>
                </Layout>
            </NavWrapper>
        );
    }
})