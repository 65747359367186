import { imgUrl, phpName } from './file';
import menuList from './menu.js';
import { getAxios } from './config';
import $ from 'jquery';

// 判断是否登录
export function isLogined(){
    if(sessionStorage.getItem('donghao_user')){
        return true;
    }
    return false;
}

// 初始化图片
export function initImage(url){
    let image
    if((/^data:image\//.test(url))){
        image = url
    }else if((/^(http|https):\/\/.+/.test(url))){
        image = url
    }else{
        image = imgUrl + url
    }
    return image
}

// 表单规则
export const rules = [{ required: true, message: '不能为空！' }];
export const rulesE = [
    {
        required: true,
        validator: (rule, value) => {
            if (value.isEmpty()) {
                return Promise.reject('请输入正文内容')
            } else {
                return Promise.resolve()
            }
        }
    }
];

// 单选
export function onSelectSome(record, selected, selectedRowKeys, selectedRows){
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            let arr = [];
            let list = [];
            if (selected) {
                arr = Array.from(new Set([...selectedRowKeys, record.id]));
                list = Array.from(new Set([...selectedRows, record]));
            } else {
                arr = selectedRowKeys.filter(item => {
                    return item !== record.id
                });
                list = selectedRows.filter(item => item.id !== record.id)
            }
            resolve([arr, list])
        }, 500)
    })
};

// 全选
export function onSelectAll(selected, changeRows, selectedRowKeys, selectedRows){
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            let arr = [];
            let list = [];
            if (selected) {
                let addSelected = [];
                let addRows = [];
                for (const item of changeRows) {
                    addSelected.push(item.id);
                    addRows.push(item);
                }
                arr = [...addSelected, ...selectedRowKeys];
                list = [...addRows, ...selectedRows];
            } else {
                const subCheckedKeys = selectedRowKeys.filter((id) => {
                    return !changeRows.some((item) => {
                        return item.id === id
                    })
                })
                const subCheckedRows = selectedRows.filter((itemS) => {
                    return !changeRows.some((item) => {
                        return item.id === itemS.id
                    })
                })
                arr = [...subCheckedKeys];
                list = [...subCheckedRows];
            }
            resolve([arr, list])
        }, 500)
    })
};

// 获取分类
export function getCategory(type){
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            let postData = {
                type,
                sort: 'weigh',
                order: 'desc'
            }
            getAxios(phpName + '/category/index', postData).then(res=>{
                let arr = []
                // 是否有数据
                if(res.total){
                    arr = res.rows
                }else{
                    // 没有就是出错
                    arr = []
                }
                resolve(arr)
            })
        }, 1000)
    })
}

// 获取需要分页的内容
export function getPaingList(list, apiname, sort, search, op){
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            let str = ''
            if(search){
                str = search
            }
            let strOp = ''
            if(op){
                strOp = op
            }
            let postData = {
                sort,
                order: 'desc',
                filter: encodeURI(str),
                op: encodeURI(strOp)
            }
            getAxios(phpName +'/' + apiname, postData).then(res=>{
                let arr = [], code = 0
                // 是否有数据
                if(res.total){
                    arr = res.rows
                    code = 1
                }else{
                    // 没有就是出错
                    arr = []
                    code = 0
                }
                resolve([arr, code])
            })
        }, 700)
    })
}

// 导出
export function exportApi(selectedRowKeys, apiname, apiname_s, filename, cat_category_id, sort){
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            let postData = {
                addtabs: 1,
                sort: 'id',
                order: 'desc',
                filter: '',
                op: '',
            }
            let url_s = phpName+ '/'+apiname+'/', link = url_s+apiname_s, ids = '?ids=' + selectedRowKeys.toString(), url= ''

            url = link + ids
            if(selectedRowKeys.length===0){
                url = link + '?cat_category_id='+cat_category_id
            }else{
                if(cat_category_id){
                    url = link + ids + '&cat_category_id='+cat_category_id
                }
                if(apiname_s === 'exportdue'){
                    url = url_s +'export' + ids + '&tips='+apiname_s
                }
            }
            $.ajax({
                url,
                type: "GET",
                params: postData,
                headers: {},
                xhrFields: { responseType: "blob" },
                success: function(res){
                    if(res.code===0){
                        resolve([res.code, res.msg])
                    }else{
                        let binaryData = [];
                        binaryData.push(res);
                        let dom = document.createElement('a')
                        let url = window.URL.createObjectURL(new Blob(binaryData))
                        dom.href = url
                        dom.download = decodeURI(filename)
                        dom.style.display = 'none'
                        document.body.appendChild(dom)
                        dom.click()
                        dom.parentNode.removeChild(dom)
                        window.URL.revokeObjectURL(url)
                        resolve([1, url])
                    }
                }
            })
        }, 1000)
    })
}

// 权限
export function judgeRule(url){
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            let userrule = sessionStorage.getItem("donghao_ruleids")
            let rulesList = ['*']
            if(userrule){
                rulesList = userrule.split(',')
            }
            let output = []
            if(rulesList[0]==='*'){
                output = ['list', 'add', 'edit', 'del', 'export', 'multi', 'import', 'create', 'bill', 'shoukuan', 'forcedownload', 'updateall', 'multilast', 'cancelmultilast','tuizu', 'suble', 'renew']
            }else{
                menuList.forEach(item=> {
                    if(item.key===url){
                        item.childrenid.forEach(itemB=> {
                            if(itemB.name==='查看'){
                                output.push('list')
                            }
                            if(itemB.name==='添加'){
                                output.push('add')
                            }
                            if(itemB.name==='编辑'){
                                output.push('edit')
                            }
                            if(itemB.name==='删除'){
                                output.push('del')
                            }
                            if(itemB.name==='导出'){
                                output.push('export')
                            }
                            if(itemB.name==='导入'){
                                output.push('import')
                            }
                            if(itemB.name==='生成费用'){
                                output.push('create')
                            }
                            if(itemB.name==='打印账单'){
                                output.push('bill')
                            }
                            if(itemB.name==='收款操作'){
                                output.push('shoukuan')
                            }
                            if(itemB.name==='下载'){
                                output.push('forcedownload')
                            }
                            if(itemB.name==='审批合同'){
                                output.push('multi')
                            }
                            if(itemB.name==='批量更新仪表'){
                                output.push('updateall')
                            }
                            if(itemB.name==='终审合同'){
                                output.push('multilast')
                            }
                            if(itemB.name==='取消终审'){
                                output.push('cancelmultilast')
                            }
                            if(itemB.name==='退租'){
                                output.push('tuizu')
                            }
                            if(itemB.name==='转租'){
                                output.push('suble')
                            }
                            if(itemB.name==='续签'){
                                output.push('renew')
                            }
                        })
                        return
                    }
                    if(item.children){
                        item.children.forEach(itemA=> {
                            if(itemA.key === url){
                                itemA.childrenid.forEach(itemB=> {
                                    if (rulesList.indexOf(itemB.id) > -1) {
                                        if(itemB.name==='查看'){
                                            output.push('list')
                                        }
                                        if(itemB.name==='添加'){
                                            output.push('add')
                                        }
                                        if(itemB.name==='编辑'){
                                            output.push('edit')
                                        }
                                        if(itemB.name==='删除'){
                                            output.push('del')
                                        }
                                        if(itemB.name==='导出'){
                                            output.push('export')
                                        }
                                        if(itemB.name==='导入'){
                                            output.push('import')
                                        }
                                        if(itemB.name==='生成费用'){
                                            output.push('create')
                                        }
                                        if(itemB.name==='打印账单'){
                                            output.push('bill')
                                        }
                                        if(itemB.name==='收款操作'){
                                            output.push('shoukuan')
                                        }
                                        if(itemB.name==='下载'){
                                            output.push('forcedownload')
                                        }
                                        if(itemB.name==='审批合同'){
                                            output.push('multi')
                                        }
                                        if(itemB.name==='批量更新仪表'){
                                            output.push('updateall')
                                        }
                                        if(itemB.name==='终审合同'){
                                            output.push('multilast')
                                        }
                                        if(itemB.name==='取消终审'){
                                            output.push('cancelmultilast')
                                        }
                                        if(itemB.name==='退租'){
                                            output.push('tuizu')
                                        }
                                        if(itemB.name==='转租'){
                                            output.push('suble')
                                        }
                                        if(itemB.name==='续签'){
                                            output.push('renew')
                                        }
                                    }
                                    
                                })
                                return
                            }
                        })
                    }
                })
            }
            
            resolve(output)
        }, 500)
    })
};