/**
 *
 * ajax全局配置
 *
 */
import axios from 'axios';
import { loginUrl } from './file';

// axios 配置
axios.defaults.timeout = 5000000; //响应时间
axios.defaults.headers['Content-Type'] = 'application/json'; //配置请求头
axios.defaults.headers['x-requested-with'] = 'XMLhttprequest'; //配置请求头
 
// 当实例创建时设置默认配置
//  axios.defaults.baseURL = apiUrl; // 允许携带cookie
axios.defaults.withCredentials = true; // 允许携带cookie
 
//http request 拦截器
axios.interceptors.request.use((config) => {
//  if(sessionStorage.getItem('donghao_token')){
//      config.headers["token"] = window.sessionStorage.getItem('donghao_token')
//  }
	return config
}, (error) => {
	return Promise.reject(error);
});
 
//http response 拦截器:返回状态判断（添加响应拦截器）
axios.interceptors.response.use(
	response => {
		const res = response
		const data = res.data
		if (res.status === 200) {
			if(data.code===0 && data.msg==="请登录后操作"){
				localStorage.clear();
				sessionStorage.clear();
				window.location = loginUrl;
			}else if(data.code===401){
				localStorage.clear();
				sessionStorage.clear();
				window.location = loginUrl;
			}
		}else if (res.status === 404) {
			// 1001 说明 token 验证失败
			// 可以直接跳转到登录页面，重新登录获取 token
			localStorage.clear();
			sessionStorage.clear();
			window.location = loginUrl;
		}
		return data;
	},
	error => {
	// alert(error)
		console.log(error)
		let data  = {
			code: error.response.status,
			msg: '出错了，请联系技术人员'
		}
		return data;
		// alert('网络出错，请稍后再试')
		// return Promise.reject(error) // 返回接口返回的错误信息
	}
);
 

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function postAxios(url, data){
    return new Promise((resolve,reject) => {
        axios.post(url, data).then(
            (response) => {
              resolve(response);
            },
            (err) => {
              reject(err);
            }
        );
    })
}
 
/**
 * @function 参数拼接
 * @param {object} obj 只支持非嵌套的对象
 * @returns {string}
 */
function params(obj) {
	let result = '';
	for (let item in obj) {
		if (item && String(obj[item])) {
			result += `&${item}=${obj[item]}`;
		}
	}
	if (result) {
		result = result.slice(1);
	}
	return result;
}
 
/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function getAxios(url, data){
	const new_url = url + '?' + params(data)
	return new Promise((resolve,reject) => {
		axios.get(new_url).then(
			(response) => {
				resolve(response);
			},
			(err) => {
				reject(err);
			}
		);
	})
}