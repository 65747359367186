
import './App.css';
// import { useEffect } from 'react';
import Nav from './component/Nav';
import Login from './pages/Login';
import { isLogined } from './static/js/auth';
import { BrowserRouter as Router } from 'react-router-dom' // BrowserRouter
let isLogin = isLogined()

function App() {
	return (
		<div className="App">
		{
			isLogin?
			<Router basename='/donghao'>
				<Nav />
			</Router>
			:
			<Login />
		}
		</div>
	);
}

export default App;
