import { lazy } from 'react'

const routes = [
    {
        path: '/login',
        redirect: lazy(() => import('../pages/Login')),
        meta: {
            title: '登录页面'
        }
    },
    {
        path: '/index',
        component: lazy(() => import('../pages/Index')),
        cache: false,
        // 如果要求严格路径
        exact: true,
        meta: {
            title: '首页'
        }
    },
    {
        path: '/admin',
        component: lazy(() => import('../pages/Admin')),
        cache: false,
        meta: {
            title: '管理员管理'
        },
        // 若有子页面，此为参考
        routes: [
            {
                path: '/admin/rules',
                cache: false,
                component:  lazy(() => import('../pages/Admin/Rules'))
            },{
                path: '/admin/list',
                cache: false,
                component:  lazy(() => import('../pages/Admin/List'))
            },{
                path: '/admin/log',
                cache: false,
                component:  lazy(() => import('../pages/Admin/Log'))
            }
        ]
    },
    {
        path: '/basics',
        cache: false,
        component: lazy(() => import('../pages/Basics')),
        meta: {
            title: '基础资料'
        },
        // 若有子页面，此为参考
        routes: [
            {
                path: '/basics/project',
                cache: false,
                component:  lazy(() => import('../pages/Category/List')),
                meta: {
                    title: '物业分类'
                },
            },
            {
                path: '/basics/property',
                cache: false,
                component:  lazy(() => import('../pages/Basics/Property')),
                meta: {
                    title: '物业组团'
                },
            },{
                path: '/basics/building',
                cache: false,
                component:  lazy(() => import('../pages/Basics/Building')),
                meta: {
                    title: '楼栋资料'
                },
            },{
                path: '/basics/housetype',
                cache: false,
                component:  lazy(() => import('../pages/Category/List')),
                meta: {
                    title: '房屋属性'
                },
            },{
                path: '/basics/room',
                cache: false,
                component:  lazy(() => import('../pages/Basics/Room')),
                meta: {
                    title: '房间资料'
                },
            },{
                path: '/basics/naturetype',
                cache: false,
                component:  lazy(() => import('../pages/Category/List')),
                meta: {
                    title: '业务性质'
                },
            },{
                path: '/basics/buildtype',
                cache: false,
                component:  lazy(() => import('../pages/Category/List')),
                meta: {
                    title: '产品类型'
                },
            },{
                path: '/basics/buildsize',
                cache: false,
                component:  lazy(() => import('../pages/Category/List')),
                meta: {
                    title: '套型'
                },
            },{
                path: '/basics/firstparty',
                cache: false,
                component:  lazy(() => import('../pages/Category/List')),
                meta: {
                    title: '甲方公司'
                },
            },{
                path: '/basics/bank',
                cache: false,
                component:  lazy(() => import('../pages/Client/Bank')),
                meta: {
                    title: '收款账户'
                },
            }
        ]
    },
    {
        path: '/detail/basics/building',
        cache: false,
        component: lazy(() => import('../pages/Basics/Building/Detail')),
        // 如果要求严格路径
        isExact: true,
        meta: {
            title: '楼栋资料详情'
        }
    },
    // {
    //     path: '/edit/basics/building',
    //     component: lazy(() => import('../pages/Basics/Building/Detail')),
    //     // 如果要求严格路径
    //     isExact: true,
    //     meta: {
    //         title: '编辑楼栋资料'
    //     }
    // },
    {
        path: '/detail/basics/room',
        cache: false,
        component: lazy(() => import('../pages/Basics/Room/Detail')),
        // 如果要求严格路径
        isExact: true,
        meta: {
            title: '编辑房间资料'
        }
    },
    {
        path: '/insert/basics/room',
        cache: false,
        component: lazy(() => import('../pages/Basics/Room/Detail')),
        // 如果要求严格路径
        isExact: true,
        meta: {
            title: '添加房间资料'
        }
    },{
        path: '/client',
        component: lazy(() => import('../pages/Client')),
        cache: false,
        meta: {
            title: '客户管理'
        },
        // 若有子页面，此为参考
        routes: [
            {
                path: '/client/infor',
                cache: false,
                component: lazy(() => import('../pages/Client/Infor')),
                // 如果要求严格路径
                exact: true,
                meta: {
                    title: '客户资料'
                }
            },{
                path: '/client/client',
                cache: false,
                component:  lazy(() => import('../pages/Category/List')),
                meta: {
                    title: '客户类型'
                },
            }
        ]
    },
    {
        path: '/detail/client/infor',
        cache: false,
        component: lazy(() => import('../pages/Client/Infor/Detail')),
        // 如果要求严格路径
        isExact: true,
        meta: {
            title: '客户资料详情'
        }
    },{
        path: '/meter',
        component: lazy(() => import('../pages/Meter')),
        cache: false,
        meta: {
            title: '仪表资料'
        },
        // 若有子页面，此为参考
        routes: [
            {
                path: '/meter/instrumentcat',
                cache: false,
                component:  lazy(() => import('../pages/Category/List')),
                meta: {
                    title: '仪表类型'
                },
            },
            {
                path: '/meter/instrumenttype',
                cache: false,
                component:  lazy(() => import('../pages/Category/List')),
                meta: {
                    title: '仪表类别'
                },
            },
            {
                path: '/meter/spec',
                cache: false,
                component: lazy(() => import('../pages/Meter/Spec')),
                // 如果要求严格路径
                exact: true,
                meta: {
                    title: '仪表规格'
                }
            },{
                path: '/meter/infor',
                cache: false,
                component:  lazy(() => import('../pages/Meter/Infor')),
                meta: {
                    title: '仪表管理'
                },
            }
        ]
    },{
        path: '/pact',
        cache: false,
        component: lazy(() => import('../pages/Pact')),
        meta: {
            title: '合同管理'
        },
        // 若有子页面，此为参考
        routes: [
            {
                path: '/pact/purpose',
                cache: false,
                component:  lazy(() => import('../pages/Category/List')),
                meta: {
                    title: '用途'
                },
            },
            {
                path: '/pact/cost',
                cache: false,
                component:  lazy(() => import('../pages/Category/List')),
                meta: {
                    title: '费用项分类'
                },
            },
            {
                path: '/pact/expense',
                cache: false,
                component:  lazy(() => import('../pages/Pact/Expense')),
                meta: {
                    title: '费用项管理'
                },
            },
            {
                path: '/pact/contract',
                cache: false,
                component:  lazy(() => import('../pages/Pact/Contract')),
                meta: {
                    title: '合同资料'
                },
            },
            {
                path: '/pact/expire',
                cache: false,
                component:  lazy(() => import('../pages/Pact/Contract')),
                meta: {
                    title: '即将到期合同'
                },
            },
            {
                path: '/pact/meter',
                cache: false,
                component:  lazy(() => import('../pages/Pact/Meter')),
                meta: {
                    title: '抄表'
                },
            },
            {
                path: '/pact/bill',
                cache: false,
                component:  lazy(() => import('../pages/Pact/Bill')),
                meta: {
                    title: '账单'
                },
            },
            {
                path: '/pact/stat',
                cache: false,
                component:  lazy(() => import('../pages/Pact/Stat')),
                meta: {
                    title: '应收统计'
                },
            },
            {
                path: '/pact/end',
                cache: false,
                component:  lazy(() => import('../pages/Pact/Contract')),
                meta: {
                    title: '已终止合同'
                },
            },
        ]
    },
    {
        path: '/zdysj',
        component: lazy(() => import('../pages/Zdysj')),
        cache: false,
        // 如果要求严格路径
        exact: true,
        meta: {
            title: '自定义收据'
        }
    },
    {
        path: '/detail/pact/contract',
        component: lazy(() => import('../pages/Pact/Contract/Detail')),
        cache: true,
        permission: true,
        // 如果要求严格路径
        isExact: true,
        meta: {
            title: '编辑合同资料'
        }
    },
    {
        path: '/insert/pact/contract',
        component: lazy(() => import('../pages/Pact/Contract/Detail')),
        cache: true,
        permission: true,
        // 如果要求严格路径
        isExact: true,
        meta: {
            title: '添加合同资料'
        }
    },
    {
        path: '/details/pact/contract',
        component: lazy(() => import('../pages/Pact/Contract/Details')),
        cache: true,
        permission: true,
        // 如果要求严格路径
        isExact: true,
        meta: {
            title: '合同资料详情'
        }
    },
    {
        path: '/approval/pact/contract',
        cache: false,
        component: lazy(() => import('../pages/Pact/Contract/Detail')),
        // 如果要求严格路径
        isExact: true,
        meta: {
            title: '审批合同资料'
        }
    },
    {
        path: '/lists/pact/contract',
        cache: false,
        component: lazy(() => import('../pages/Pact/Contract/Detail/Bill')),
        // 如果要求严格路径
        isExact: true,
        meta: {
            title: '账单详情'
        }
    },
    {
        path: '/detail/pact/meter',
        cache: false,
        component: lazy(() => import('../pages/Pact/Meter/Detail')),
        // 如果要求严格路径
        isExact: true,
        meta: {
            title: '抄表详情'
        }
    },
    {
        path: '/404',
        redirect: lazy(() => import('../pages/NotFound')),
        meta: {
            title: '404'
        }
    },
]

export default routes
