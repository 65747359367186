export const phpName = "/EdQUnFSGKJ.php";
export const uploadUrl = phpName+"/ajax/upload";
export const imgUrl = window.location.origin;
// export const imgUrl = 'http://dhwy.zg1992.com';
export const apiUrl = window.location.origin;
// export const apiUrl = 'http://dhwy.zg1992.com';

// export const homeUrl = "http://localhost:3000/donghao/index";
// export const loginUrl = "http://localhost:3000/donghao/login";

export const homeUrl = window.location.origin+"/donghao/index";
export const loginUrl = window.location.origin+"/donghao/login";
