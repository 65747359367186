import styled from 'styled-components'

export const NavWrapper = styled.div `
    .site-layout{
        overflow-x: hidden;
    }
    /* .ant-menu-title-content a,
    .ant-menu-submenu-arrow,
    .ant-menu-item-icon{
        color: #fff;
    }
    
    .ant-menu-title-content .active,
    .ant-menu-item-selected .ant-menu-item-icon{
        color: #000000;
    }
    .ant-menu-submenu-selected .ant-menu-title-content,
    .ant-menu-submenu-selected .ant-menu-item-icon,
    .ant-menu-submenu-selected .ant-menu-submenu-arrow,
    .ant-menu-light .ant-menu-submenu-active .ant-menu-title-content,
    .ant-menu-light .ant-menu-submenu-active,
    .ant-menu-light .ant-menu-submenu-active .ant-menu-item-icon,
    .ant-menu-light .ant-menu-item-active,
    .ant-menu-light .ant-menu-item-active .ant-menu-item-icon,
    .ant-menu-item a:hover,
    .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
    .ant-menu-item-selected a,
    .ant-menu-item-selected .ant-menu-item-icon{
        color: #7eaf8d!important;
    }
    a,
    a:hover{
        color: #05773F;
        background-color: unset;
    } */
    
    /* 左侧 */
    /* .ant-layout-sider,
    .ant-menu{
        background-color: #243B2B!important;
        color: #fff;
    } */
    /* 左侧Logo */
    .ant-layout{        
        .logo {
            height: 32px;
            margin: 16px;
            background: rgba(255, 255, 255, 0.3);
            text-align: center;
            line-height: 32px;
            a{
                color: #fff;
            }
        }
        /* 右侧上部 */
        .page-header{
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            text-align: right;
            background: #fff;
            font-size: 14px;
            box-shadow: 0px 1px 4px 1px rgba(0,21,41,0.12);

            /* 上部--左 */
            .bread-box{
                background-color: #fff;
                padding-left: 15px;
            }
            /* 上部--右 */
            .header-right{
                .right_span{
                    cursor: pointer;
                    margin-left: 10px;
                }

                .ant-select-selection-item{
                    text-align: left;
                }
                .ant-select-selector{
                    border: none;
                }
                .ant-select{
                    &:hover{
                        .ant-select-selector{
                            border: none;
                            box-shadow: none;
                        }
                    }
                }
                .ant-select-focused{
                    .ant-select-selector{
                        border: none;
                        box-shadow: none;
                    }
                }
            }
        }
        /* 右侧内容 */
        .ant-layout-content{
            padding: 24px 24px 0 24px;
        }
        .page-content{
            min-height: 100%;
            background-color: #fff;
            
            .ant-tabs-tab{
                border-left: none!important;
                border-top: none!important;
                border-right: none!important;
                margin-left: 0!important;
                border-radius: 0!important;
            }
        }
    }
    .ant-btn:not(:disabled):focus-visible{
        outline: none;
    }
`
