import styled from 'styled-components'

export const LoginWrapper = styled.div `
	height: 100vh;
	position: relative;

    .bgimg{
		width: 100%;
		height: 100%;
		position: absolute;
	}

	.word-box{
		position: absolute;
		font-weight: bold;
		left: 8%;
		top: 35%;
		transform: translate(0, -35%);

		.english{
			color: #2C2C2C;
			font-size: 60px;
		}
		.chinese{
			color: #666666;
			font-size: 20px;
			margin-top: 10px;
		}
	}

	.LoginBox{
		width: 20%;
		padding-top: 0;
		background-color: #fff;
		box-shadow: 0px 7px 13px 1px rgba(111,131,178,0.24);;
		border-radius: 16px;
		position: absolute;
		right: 3%;
		top: 50%;
		transform: translate(0, -50%);
		padding: 70px 51px 68px 58px;

		.title{
			font-size: 22px;
            font-weight: bold;
			font-stretch: normal;
			color: #398AF6;
			margin-bottom: 83px;

			&::after{
				content: '';
				width: 32px;
				height: 7px;
				background-color: #398AF6;
				margin: auto;
				margin-top: 14px;
				border-radius: 4px;
			}
		}

		.ant-form {
			.ant-form-item{
				margin-bottom: 46px;
				border-bottom: 1px solid #E0E0E0;

				.ant-input-password{
					border: none;
					padding: 0;

					&:hover{
						border: none;
					}
					&:focus,
					:where(.css-dev-only-do-not-override-ixblex).ant-input-affix-wrapper-focused,
					:where(.css-ixblex).ant-input-affix-wrapper:focus, 
					:where(.css-ixblex).ant-input-affix-wrapper-focused {
						border: none;
						box-shadow: none;
					}
					.ant-input-suffix{
						padding: 0 0 18px 0;
					}
				}
				:where(.css-dev-only-do-not-override-ixblex).ant-input-affix-wrapper-focused,
				:where(.css-ixblex).ant-input-affix-wrapper:focus, 
				:where(.css-ixblex).ant-input-affix-wrapper-focused {
					border: none;
					box-shadow: none;
				}
				.ant-input{
					border: none;
					border-radius: 0;
					padding: 0 0 18px 8px;
					color: #398AF6;
            		font-weight: bold;
					font-size: 18px;

					&:focus{
						box-shadow: none;
					}
				}
			}

			.btn-item{
                margin-top: 64px;
				margin-bottom: 0;

				.ant-btn {
					width: 100%;
					height: 100%;
					border-radius: 12px;
					font-size: 20px;
            		font-weight: bold;
					padding: 16px 0;
				}
			}
		}
	}
`;