
import { DesktopOutlined, FileOutlined, PieChartOutlined, UserOutlined } from '@ant-design/icons';

const menuList = [
    {
        id: '1',
        // hide: false,
        icon: <DesktopOutlined />,
        key: '/index',
        label: '首页',
        url: "/index",
        childrenid: [
            {
                "id": "126",
                "name": "查看"
            },{
                "id": "128",
                "name": "添加"
            },{
                "id": "130",
                "name": "删除"
            },{
                "id": "129",
                "name": "编辑"
            }
        ]
    },{
        id: '5',
        // hide: false,
        icon: <UserOutlined />,
        key: '/admin',
        label: '账号管理',
        children: [
            {
                id: '11',
                // hide: false,
                key: '/admin/rules',
                label: '角色组',
                url: "/admin/rules",
                childrenid: [
                    {
                        "id": "47",
                        "name": "查看"
                    },{
                        "id": "48",
                        "name": "添加"
                    },{
                        "id": "49",
                        "name": "编辑"
                    },{
                        "id": "50",
                        "name": "删除"
                    }
                ]
            },
            {
                id: '9',
                // hide: false,
                key: '/admin/list',
                label: '管理员',
                url: "/admin/list",
                childrenid: [
                    {
                        "id": "40",
                        "name": "查看"
                    },{
                        "id": "41",
                        "name": "添加"
                    },{
                        "id": "42",
                        "name": "编辑"
                    },{
                        "id": "43",
                        "name": "删除"
                    }
                ]
            },
            {
                id: '10',
                // hide: false,
                key: '/admin/log',
                label: '操作日志',
                url: "/admin/log",
                childrenid: [
                    {
                        "id": "44",
                        "name": "查看"
                    }
                ]
            },
        ]
    },{
        id: '144',
        // hide: false,
        icon: <FileOutlined />,
        key: '/basics',
        label: '基础资料',
        children: [
            {
                id: '3',
                // hide: false,
                key: '/basics/project',
                label: '物业分类',
                url: "/basics/project",
                childrenid: [
                    {
                        "id": "35",
                        "name": "查看"
                    },{
                        "id": "36",
                        "name": "添加"
                    },{
                        "id": "37",
                        "name": "编辑"
                    },{
                        "id": "38",
                        "name": "删除"
                    }
                ]
            },
            {
                id: '93',
                // hide: false,
                key: '/basics/property',
                label: '物业组团',
                url: "/basics/property",
                childrenid: [
                    {
                        "id": "94",
                        "name": "查看"
                    },{
                        "id": "96",
                        "name": "添加"
                    },{
                        "id": "97",
                        "name": "编辑"
                    },{
                        "id": "98",
                        "name": "删除"
                    }
                ]
            },
            {
                id: '102',
                // hide: false,
                key: '/basics/building',
                label: '楼栋资料',
                url: "/basics/building",
                childrenid: [
                    {
                        "id": "103",
                        "name": "查看"
                    },{
                        "id": "105",
                        "name": "添加"
                    },{
                        "id": "106",
                        "name": "编辑"
                    },{
                        "id": "107",
                        "name": "删除"
                    }
                ]
            },
            // {
            //     id: '233',
            //     // hide: false,
            //     key: '/basics/housetype',
            //     label: '房屋属性',
            //     url: "/basics/housetype"
            // },
            {
                id: '111',
                // hide: false,
                key: '/basics/room',
                label: '房间资料',
                url: "/basics/room",
                childrenid: [
                    {
                        "id": "112",
                        "name": "查看"
                    },{
                        "id": "114",
                        "name": "添加"
                    },{
                        "id": "115",
                        "name": "编辑"
                    },{
                        "id": "116",
                        "name": "删除"
                    },{
                        "id": "196",
                        "name": "批量更新仪表"
                    },{
                        "id": "198",
                        "name": "导出"
                    }
                ]
            },
            {
                id: '3',
                // hide: false,
                key: '/basics/buildtype',
                label: '产品类型',
                url: "/basics/buildtype",
                childrenid: [
                    {
                        "id": "35",
                        "name": "查看"
                    },{
                        "id": "36",
                        "name": "添加"
                    },{
                        "id": "37",
                        "name": "编辑"
                    },{
                        "id": "38",
                        "name": "删除"
                    }
                ]
            },
            {
                id: '3',
                // hide: false,
                key: '/basics/firstparty',
                label: '甲方公司',
                url: "/basics/firstparty",
                childrenid: [
                    {
                        "id": "35",
                        "name": "查看"
                    },{
                        "id": "36",
                        "name": "添加"
                    },{
                        "id": "37",
                        "name": "编辑"
                    },{
                        "id": "38",
                        "name": "删除"
                    }
                ]
            },
            {
                id: '189',
                // hide: false,
                key: '/basics/bank',
                label: '收款账户',
                url: "/basics/bank",
                childrenid: [
                    {
                        "id": "190",
                        "name": "查看"
                    },{
                        "id": "191",
                        "name": "添加"
                    },{
                        "id": "192",
                        "name": "编辑"
                    },{
                        "id": "193",
                        "name": "删除"
                    }
                ]
            },
        ]
    },{
        id: '138',
        // hide: false,
        icon: <PieChartOutlined />,
        key: '/client',
        label: '客户管理',
        children: [
            {
                id: '3',
                // hide: false,
                key: '/client/client',
                label: '客户类型',
                url: "/client/client",
                childrenid: [
                    {
                        "id": "35",
                        "name": "查看"
                    },{
                        "id": "36",
                        "name": "添加"
                    },{
                        "id": "37",
                        "name": "编辑"
                    },{
                        "id": "38",
                        "name": "删除"
                    }
                ]
            },
            {
                id: '138',
                // hide: false,
                key: '/client/infor',
                label: '客户资料',
                url: "/client/infor",
                childrenid: [
                    {
                        "id": "139",
                        "name": "查看"
                    },{
                        "id": "140",
                        "name": "添加"
                    },{
                        "id": "141",
                        "name": "编辑"
                    },{
                        "id": "142",
                        "name": "删除"
                    },{
                        "id": "197",
                        "name": "导出"
                    }
                ]
            }
        ]
    },{
        id: '145',
        // hide: false,
        icon: <PieChartOutlined />,
        key: '/meter',
        label: '仪表资料',
        children: [
            {
                id: '3',
                // hide: false,
                key: '/meter/instrumentcat',
                label: '仪表类型',
                url: "/meter/instrumentcat",
                childrenid: [
                    {
                        "id": "35",
                        "name": "查看"
                    },{
                        "id": "36",
                        "name": "添加"
                    },{
                        "id": "37",
                        "name": "编辑"
                    },{
                        "id": "38",
                        "name": "删除"
                    }
                ]
            },
            {
                id: '3',
                // hide: false,
                key: '/meter/instrumenttype',
                label: '仪表类别',
                url: "/meter/instrumenttype",
                childrenid: [
                    {
                        "id": "35",
                        "name": "查看"
                    },{
                        "id": "36",
                        "name": "添加"
                    },{
                        "id": "37",
                        "name": "编辑"
                    },{
                        "id": "38",
                        "name": "删除"
                    }
                ]
            },
            {
                id: '120',
                // hide: false,
                key: '/meter/spec',
                label: '仪表规格',
                url: "/meter/spec",
                childrenid: [
                    {
                        "id": "121",
                        "name": "查看"
                    },{
                        "id": "123",
                        "name": "添加"
                    },{
                        "id": "124",
                        "name": "编辑"
                    },{
                        "id": "125",
                        "name": "删除"
                    }
                ]
            },
            {
                id: '129',
                // hide: false,
                key: '/meter/infor',
                label: '仪表管理',
                url: "/meter/infor",
                childrenid: [
                    {
                        "id": "130",
                        "name": "查看"
                    },{
                        "id": "132",
                        "name": "添加"
                    },{
                        "id": "133",
                        "name": "编辑"
                    },{
                        "id": "134",
                        "name": "删除"
                    }
                ]
            },
        ]
    },{
        id: '164',
        // hide: false,
        icon: <PieChartOutlined />,
        key: '/pact',
        label: '合同管理',
        children: [
            {
                id: '3',
                // hide: false,
                key: '/pact/purpose',
                label: '用途',
                url: "/pact/purpose",
                childrenid: [
                    {
                        "id": "35",
                        "name": "查看"
                    },{
                        "id": "36",
                        "name": "添加"
                    },{
                        "id": "37",
                        "name": "编辑"
                    },{
                        "id": "38",
                        "name": "删除"
                    }
                ]
            },
            {
                id: '3',
                // hide: false,
                key: '/pact/cost',
                label: '费用项分类',
                url: "/pact/cost",
                childrenid: [
                    {
                        "id": "35",
                        "name": "查看"
                    },{
                        "id": "36",
                        "name": "添加"
                    },{
                        "id": "37",
                        "name": "编辑"
                    },{
                        "id": "38",
                        "name": "删除"
                    }
                ]
            },
            {
                id: '155',
                // hide: false,
                key: '/pact/expense',
                label: '费用项管理',
                url: "/pact/expense",
                childrenid: [
                    {
                        "id": "156",
                        "name": "查看"
                    },{
                        "id": "158",
                        "name": "添加"
                    },{
                        "id": "159",
                        "name": "编辑"
                    },{
                        "id": "160",
                        "name": "删除"
                    }
                ]
            },
            {
                id: '146',
                // hide: false,
                key: '/pact/contract',
                label: '合同资料',
                url: "/pact/contract",
                childrenid: [
                    {
                        "id": "147",
                        "name": "查看"
                    },{
                        "id": "149",
                        "name": "添加"
                    },{
                        "id": "150",
                        "name": "编辑"
                    },{
                        "id": "151",
                        "name": "删除"
                    },{
                        "id": "188",
                        "name": "打印账单"
                    },{
                        "id": "195",
                        "name": "收款操作"
                    },{
                        "id": "154",
                        "name": "审批合同"
                    },{
                        "id": "200",
                        "name": "导出"
                    },{
                        "id": "203",
                        "name": "终审合同"
                    },{
                        "id": "204",
                        "name": "退租"
                    },{
                        "id": "205",
                        "name": "转租"
                    },{
                        "id": "206",
                        "name": "续签"
                    },{
                        "id": "207",
                        "name": "取消终审"
                    }
                ]
            },
            {
                id: '146',
                // hide: false,
                key: '/pact/expire',
                label: '即将到期合同',
                url: "/pact/expire",
                childrenid: [
                    {
                        "id": "147",
                        "name": "查看"
                    }
                ]
            },
            {
                id: '179',
                // hide: false,
                key: '/pact/meter',
                label: '抄表',
                url: "/pact/meter",
                childrenid: [
                    {
                        "id": "174",
                        "name": "查看"
                    },{
                        "id": "175",
                        "name": "添加"
                    },{
                        "id": "176",
                        "name": "编辑"
                    },{
                        "id": "177",
                        "name": "删除"
                    },{
                        "id": "180",
                        "name": "生成费用"
                    },{
                        "id": "201",
                        "name": "导出"
                    },{
                        "id": "202",
                        "name": "导入"
                    }
                ]
            },
            {
                id: '181',
                // hide: false,
                key: '/pact/bill',
                label: '账单',
                url: "/pact/bill",
                childrenid: [
                    {
                        "id": "182",
                        "name": "查看"
                    },{
                        "id": "187",
                        "name": "下载"
                    }
                ]
            },
            {
                id: '165',
                // hide: false,
                key: '/pact/stat',
                label: '应收统计',
                url: "/pact/stat",
                childrenid: [
                    {
                        "id": "166",
                        "name": "查看"
                    },
                    {
                        "id": "199",
                        "name": "导出"
                    }
                ]
            },
            {
                id: '146',
                // hide: false,
                key: '/pact/end',
                label: '已终止合同',
                url: "/pact/end",
                childrenid: [
                    {
                        "id": "147",
                        "name": "查看"
                    }
                ]
            }
        ]
    },
    {
        id: '208',
        // hide: false,
        key: '/zdysj',
        label: '自定义收据',
        url: "/zdysj",
        childrenid: [
            {
                "id": "209",
                "name": "查看"
            },{
                "id": "211",
                "name": "添加"
            },{
                "id": "212",
                "name": "编辑"
            },{
                "id": "213",
                "name": "删除"
            }
        ]
    },
];
export default menuList